import './App.css';
import axios from 'axios';
import {React, useState, useEffect, useLayoutEffect, Suspense, lazy } from 'react';
import { useTheme } from '@mui/material/styles';
import Navbar2 from './Components/Navbar'
import Footer from './Components/Footer'
import About from './Components/About'
import Contact from './Components/Contact'
import 'bootstrap/dist/css/bootstrap.min.css';

const StockCharts = lazy(() => import('./Components/StockCharts'));
const Indexes = lazy(() => import('./Components/Indexes'));
const Sectors = lazy(() => import('./Components/Sectors'));

const Strategy = lazy(() => import( './Components/Proofs/Strategy'));
const AnalysingPrice = lazy(() => import(  './Components/Proofs/Analysing_Price'));
const Benchmark = lazy(() => import(  './Components/Proofs/Benchmark'));
const Results = lazy(() => import(  './Components/Proofs/Results'));
const WhatsNext = lazy(() => import(  './Components/Proofs/Whats_Next'));
const SelectingMarket = lazy(() => import(  './Components/Proofs/Selecting_Market'));
const LandingPage = lazy(() => import(  './Components/LandingPage'));
const Pagination = lazy(() => import(  './Components/Proofs/Pagination'));

const App = () => {

	// const apiUrl = 'http://localhost:8080/api/'   				
	const apiUrl = window.location.origin+'/api/'   				
	//console.log('apiUrl = ', apiUrl)

	const [state, setState] = useState(	{ asset : {component : 'stocks', exchange : 'NYSE', type : 'stock', code : 'AA', } } )
	const [displayState, setDisplayState] = useState();
	const [allExchanges, setAllExchanges] = useState();
	const [selection, setSelection] = useState();
	const [navSelection, setNavSelection] = useState({component : 'stocks', exchange : 'NYSE', type : 'stock', code : 'AA', });
	const [registeredUser, setRegisteredUser] = useState(); 
	const theme = useTheme();

	function getComponentClass(e) {
		var retval = undefined
		for (const [key, value] of Object.entries(theme.breakpoints.values)) {
			if (window.innerWidth >= value){
				retval = key
			}
		}
		return retval
	}

	function updateSize(e) {
		const portrait = (window.screen?.orientation?.type?.startsWith('portrait'))
		const cc = getComponentClass()

		const device = {
			mobile : false,
			portrait : portrait, 
			componentClass : cc,
			width : window.innerWidth,
			height : window.innerHeight,
			aspectRatio : (window.innerHeight/window.innerWidth).toFixed(2)
		}
		setDisplayState(device)
	}

	const onLogout = async () => {
		// console.log('onLogout')
		localStorage.removeItem('user')	
		setRegisteredUser(null)
		// setNavSelection({component : 'landing' });
	}

	const onSuccessfulLogin = async(registered_user) =>{
		// console.log('onSuccessfulLogin', registered_user)
		localStorage.setItem('user', JSON.stringify(registered_user))
		localStorage.setItem('last_login_email', registered_user.email)
		setNavSelection( {component : 'stocks', exchange : 'NYSE', type : 'stock', code : 'AA', } )
		setState({ asset : {component : 'stocks', exchange : 'NYSE', type : 'stock', code : 'AA', } } )
		setRegisteredUser(registered_user)
	}

	const exchangesCallback = async (e) => {
		setAllExchanges(e)
	}

	const selectionCallback = async(e) => {
		// console.log(e)
		setSelection(e)
	}

	useEffect(() => {
		updateSize()
	}, []) 

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		return () => window.removeEventListener('resize', updateSize);
	});

	useEffect(() => {
		try {
	    const parsedObject = JSON.parse(localStorage.getItem('user')	);
	    setRegisteredUser(parsedObject)
		} catch (error) {
		    console.error("Parsing error:", error.message);
		}
	}, []) 

//////////////////////
//// Pagination //////
//////////////////////

	const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 6;

	const onNavClick = async (e, o) => {
		// console.log('onNavClick', e)
    // Handle page change from Navbar
		var page = 0
		switch(e.component){
	    case 'strategy' : page = 1
	    	break
			case 'analysing-price' : page = 2
	    	break
			case 'benchmark' : page = 3
	    	break
	    case 'selecting-market' : page = 4
	    	break
	    case 'results' : page = 5
	    	break
	    case 'whats-next' : page = 6
	    	break
			default : setCurrentPage(6)
		}
		await setCurrentPage(page)
		setNavSelection(e)
	}

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Handle page change from Navigation

    var component = undefined
		switch(page){
		case 1 : component = 'strategy'
			break

		case 2 : component = 'analysing-price'
			break

		case 3 : component = 'benchmark'
			break

		case 4 : component = 'selecting-market'
			break

		case 5 : component = 'results'
			break

		case 6 : component = 'whats-next'
			break

		default: component = 'landing'
		}
		setNavSelection( {component : component } )

  };

	var fragment = undefined
	if (!registeredUser){
		return (
			<div className="App"> 
				<LandingPage apiUrl = {apiUrl} onNavClick={onNavClick} onSuccessfulLogin={onSuccessfulLogin}  onLogout={onLogout} state = {state}/>
			</div>
		);
	} 

	console.log('setting axios default headers')
	const sessionToken = registeredUser.password_hash
	axios.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`;
	axios.defaults.headers.post['Content-Type'] = 'application/json';


	switch (navSelection.component) {
		case 'stocks' : 
			fragment = <StockCharts apiUrl = {apiUrl} state = {state} displayState = {displayState} setState = {setState} allExchanges = {allExchanges} selection = {selection} selectionCallback = {selectionCallback} exchangesCallback = {exchangesCallback}/>
			break;
		case 'landing' :
			// fragment = <LandingPage last_login_email = {registeredUser?.email} onNavClick={onNavClick} onSuccessfulLogin={onSuccessfulLogin}  onLogout={onLogout} state = {state}/>
		break
		case 'indexes' : 
			fragment = <Indexes apiUrl = {apiUrl} state = {state } navSelection = {navSelection} displayState = {displayState}  /> 
			break;
		case 'sectors' : 
			fragment = <Sectors apiUrl = {apiUrl} onClick={onNavClick} state = {state} displayState = {displayState} /> 
			break;

		case 'strategy' : 
			fragment = 
			<div>
				<Strategy onClick={onNavClick} state = {state}  /> 
	      <Pagination name = 'Strategy' currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
			</div>
			break;
		case 'analysing-price' : 
			fragment = 
			<div>
				<AnalysingPrice onClick={onNavClick} state = {state}  /> 
	      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
			</div>
			break;
		
		case 'benchmark' : 
			fragment = <div>
				<Benchmark onClick={onNavClick} state = {state}  /> 
	      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
			</div>
			break;

		case 'selecting-market' : 
			fragment = 
			<div>
				<SelectingMarket onClick={onNavClick} state = {state}  /> 
	      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
			</div>
			break;

		case 'results' : 
			fragment = 
			<div>
				<Results onClick={onNavClick} state = {state}  /> 
	      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
			</div>
			break;

		case 'whats-next' : 
			fragment = 
			<div>
				<WhatsNext onClick={onNavClick} state = {state}  /> 
	      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
			</div>
			break;

		case 'about' : 
			fragment = <About />
			break;
		default :
			fragment = <Contact state = {state} /> 
			break;
	}

	return (
		<div className="App" > 
			<Navbar2 apiUrl = {apiUrl} registeredUser = {registeredUser} onNavClick={onNavClick} onLogout={onLogout} />
				<Suspense fallback={<div>Loading...</div>}>
					{fragment}
				</Suspense>
			<Footer/>
		</div>
	);
}

export default App;


