import '../css/Article.css';

import gold_circuits from '../assets/images/gold circuits.jpg';
import gold_and_silver from '../assets/images/gold and silver.jpg';
import gold_engineering from '../assets/images/gold engineering.jpg';
import Theseus_and_the_Golden_Thread from '../assets/images/Theseus and the Golden Thread.jpeg';
import Wall_Street_Journal from '../assets/images/Wall Street Journal.png';

export default function About (props) {

	return(
		<div id="root" >
			<div className="App" >
				<div className="container main-content" style={{maxWidth: '70vw'}} >
					<article className="article" >

						<h1> About The Project</h1>
						<p>
							The Theseus Project is the culmination of an obsession,
							stretching back more than 10 years. It began with a simple
							question:
						</p >
						<div className="main-block">
							<h3 className="blockquote">"Can the Gold and Silver markets be beaten?"</h3 >
						</div>
						<p>
							As a child, I was always fascinated with how the world worked and
							wondering why things were the way they were. When I grew up, this
							general curiosity led me into a career in Information Systems
							Design, and allowed me to help build complex systems at Scotland
							Yard and British Aerospace.
						</p>
						<p>My interest in investing and world equity markets took a lot
							longer to develop. Throughout the early 2000s, I started noticing
							the steady and relentless increase in the price of Gold. Year
							after year it climbed, and I became increasingly fascinated by
							this shiny metal, and its little sister, Silver.
						</p>

						<div className="flex-card">
							<img src={gold_circuits} alt="gold_circuits" />
							<p style= {{minHeight: '160px'}} >
								I studied the history of precious metals and learned about their
								rarity and how hard they are to mine. I discovered how chemically
								stable they are, and how gold doesn’t tarnish or corrode, even
								over hundreds of years. That’s why both gold and silver still have
								vital applications in electronics, aerospace, solar panels,
								medical technology, chemical production and the automotive
								industry.
							</p >
						</div>

						<div className="flex-card flex-reverse">
							<img src={gold_engineering} alt="gold_engineering" />
							<p style= {{minHeight: '220px'}}>

								Perhaps most importantly, both gold and silver had been regarded
								throughout history as a vital store of value and a safe haven
								during times of economic turmoil when government fiat currencies
								imploded and inflation became rampant.
							
							 <span style={{display: 'block', height: '15px'}}></span>

								In March 2008, gold finally touched the landmark figure of
								$1,000 per ounce, meaning it had almost quadrupled in value since
								I started watching it some 7 years earlier. Yet, in all of that
								time I hadn’t bought a single ounce.
							</p >
						</div>

						<div className="flex-card ">
							<img src={Wall_Street_Journal} alt="Wall_Street_Journal" />
							<p>
							As the global financial crisis took hold that year, we witnessed
							the potential collapse of the world financial system. Major
							financial institutions, including Lehman Brothers, Bear Stearns,
							and AIG, faced severe liquidity crises and massive losses due to
							their exposure to subprime mortgages and related securities.

							 <span style={{display: 'block', height: '15px'}}></span>

							Governments around the world intervened with massive bailout packages to
							stabilize banks and financial institutions. In the U.S., the
							Troubled Asset Relief Program (TARP) provided $700 billion to
							purchase distressed assets and inject capital into banks. Global
							stock markets plummeted as investor confidence eroded, wiping out
							trillions of dollars in wealth. 
							 <span style={{display: 'block', height: '15px'}}></span>
							Central banks, including the Federal Reserve, cut interest
							rates to near zero and implemented unconventional monetary
							policies like quantitative easing to stimulate the economy
							</p>
						</div>

						<div className="flex-card flex-reverse" styling={{ marginBlock : '2px!important'}}>
						 <img src={gold_and_silver} alt="gold_and_silver" />
						 <p style= {{minHeight: '20px'}} >
						 	As stock markets crashed and lost half their value in a year,
							gold and silver also fell, though by much less. By early 2009,
							Gold had regained most of its losses and appeared to be well on
							its way back to the $1000 mark. With so much financial
							uncertainty, it looked like an excellent investment to me and I
							finally started to buy.
							 <span style={{display: 'block', height: '15px'}}></span>
							I started buying 1-ounce Canadian
							Gold Maple Leaf coins and progressed from there.
						 <span style={{display: 'block', height: '15px'}}></span>

							Compared with a price of almost $1,000 an ounce for gold, silver looked
							stunningly cheap at around $10 or $11 an ounce, and I started to
							buy 1,000 ounce bars, weighing over 60 pounds. 

							 <span style={{display: 'block', height: '15px'}}></span>

							I continued buying,
							as funds allowed, through 2009 and 2010. By early 2011, Silver hit
							$48 an ounce, four times the price I had first paid. By September,
							gold had almost doubled in price, but silver had started to fall
							back.
							 <span style={{display: 'block', height: '15px'}}></span>

							I didn’t sell any, and as 2011 turned into 2012, the price
							of both continued to fall. I sold some over the next few years and
							then liquidated the remainder in 2016, having watched much of
							those stupendous profits evaporate.
							</p >
						</div>

						<p>
							I went through the typical stages of denial and anger before
							finally coming to acceptance. In some ways, it had been a
							potentially life-changing experience. I had hit upon the
							incredible potential of the gold and silver markets, and how
							massively profitable they can be in turbulent times. 

							 <span style={{display: 'block', height: '15px'}}></span>

							And yet my understanding of what drove prices in those markets was
							extraordinarily limited. I had been drawn to them for purely
							fundamental reasons without any conception of the technical
							reasons that move prices in these markets, through repeated cycles
							over time.
						</p>

						<p>
							I vowed that one day I would have the means and the technology to
							definitively answer the question: “Can the gold and silver markets
							be beaten?”
						</p>

						<p>
							Since posing that question in 2016, advances in cloud computing
							and multi-core technology has allowed the processing and analysis
							of vast amounts of data at speeds 5 times greater than would have
							been possible even then.
						</p>
						<p>
							Detailed research work for the Theseus Project began in 2020, and
							design, testing and data discovery in 2021. Proof of Concept was
							finally released in August 2024.
						</p>
						 <span style={{display: 'block', height: '1px'}}></span>
						<h2> Why Theseus? </h2>
						<p>
							The ancient myth of Theseus and the Minotaur dates back more than
							3,000 years to the Mycenaeans in Greece and the Minoan
							civilisation on Crete.

							 <span style={{display: 'block', height: '15px'}}></span>

							As Joseph Campbell taught, myths are not
							just silly stories but vital expressions of human consciousness,
							reflecting our deepest fears, aspirations, and quests for meaning.
							That’s why the same themes crop up in myths from all cultures -
							because they’re central to the human condition.
						</p >
						<p>
							While working on this project, I noticed similarities between
							themes in ancient myths and universal patterns that recurred in
							financial markets throughout history. 
							 <span style={{display: 'block', height: '15px'}}></span>

							I believe this is because
							they’re both expressions of human consciousness, with bull markets
							representing periods of ambition, aspiration and expansion,
							followed by bear markets representing periods of apathy,
							contraction and fear.
						</p>
						<p>
							In some versions of the Theseus myth, the thread that Ariadne
							gives Theseus to help him navigate the Labyrinth is described as a
							golden thread. This thread is crucial in allowing Theseus to find
							his way back after slaying the Minotaur.</p
						><p>
							We are facing exceptionally turbulent economic and political times
							ahead.
						</p>
						<p>
							Against this backdrop, the gold and silver markets can offer
							potentially life-changing rewards in the next few years, while
							posing significant challenges along the way. 

							 <span style={{display: 'block', height: '15px'}}></span>
							It’s my hope that The
							Theseus Project will help many people to profit from them.
						</p>
					</article>
				</div>
			</div>
			<div className='footer'>
				<img src={Theseus_and_the_Golden_Thread} alt="gold_and_silver" />
			</div>
		</div>
)}
