import '../App.css';
// import axios from 'axios';
import logo from '../assets/images/theseus 250x150 tsp.png';
// import Overlay from 'react-bootstrap/Overlay';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import { React, useState, useEffect,  } from 'react';
import { Navbar, Nav, NavbarBrand, NavbarToggler, Collapse }  from 'reactstrap'
import { Button, Form, Dropdown,   } from 'react-bootstrap';
// import Select from 'react-select'
import { FaAlignJustify } from 'react-icons/fa';
// import { FiLogOut, FiLogIn } from 'react-icons/fi';
import menu_config from './menu_config.json';

export default function Navbar2(props) {

	// const state = props.state
	// const data_url = props.base_url
	const [indexes, setIndexes] = useState()
	// const [active, setActive] = useState('stocks')
	const [open, setOpen] = useState()
	// const [tooltipContent, setTooltipContent] = useState('Awooga')
	const [registeredUser] = useState(props.registeredUser);

	useEffect(() => {
		getIndexes()
	}, [])  

	useEffect(() => {
		// setTooltipContent(props.user?.ok ? props.user?.status : 'not signed in')
	}, [props.user])  

	const getIndexes = async () =>{
		// const response = await axios.post(data_url+'/indexes');
		// setIndexes(response.data)
		setIndexes(menu_config)
	}

	const onToggle = (e) => {
		// console.log('onToggle')
		setOpen(!open)
	}

	// const screenWidth = localStorage.getItem('widthPercent') || .35
	// const tooltip = (<Tooltip id="tooltip"> { tooltipContent} </Tooltip> );
	// const [show, setShow] = useState(false);
	// const handleClose = () => {setShow(false) }
	// const handleShow = () => setShow(true);
	// const registered_user = JSON.parse(props?.user);

	// try {
  //   const parsedObject = JSON.parse(localStorage.getItem('user')	);
  //   console.log(parsedObject);
	// } catch (error) {
	//     console.error("Parsing error:", error.message);
	// }

	// const onInputChange = (options, { action }) => {
  //   if (action === "menu-close") {
  //     this.setState({ menuIsOpen: false });
  //   }
  // };

  // console.log(registeredUser)

	return(
		<div className = 'navbar-container'>

			<Navbar id = 'navbar' bg = 'light' variant = 'dark' className='navbar' expand="md" light >
				<NavbarBrand href="/" style = {{'backgroundColor' : '#003366', paddingTop : 0.25}}> 
					<img src={logo} style={{ 'height' : 75, 'width' : 125}} alt="logo" /> 
				</NavbarBrand>
				<NavbarToggler style = {{color : 'ivory'}} onClick={onToggle}> 
					<FaAlignJustify /> 
				</NavbarToggler>
				<Collapse isOpen={open} navbar> 

					<Nav className="me-auto navbar-brand abs" > 

						<Dropdown> <Dropdown.Toggle id = 'proofs'  > Proof of Concept </Dropdown.Toggle>
								<Dropdown.Menu > {indexes?.proofs?.map((e, key) =>
									<Dropdown.Item key={key}  
									onClick = { () => props.onNavClick(e) }> {e.name} </Dropdown.Item>)}
							</Dropdown.Menu> 
						</Dropdown>

						<Button id = 'sectors' onClick = { () => props.onNavClick({ code : "", component : "sectors",}) } >Sectors</Button>
						<Dropdown >
							<Dropdown.Toggle > Indexes </Dropdown.Toggle>
							<Dropdown.Menu > {indexes?.indexes?.map((e, key) => 
								<Dropdown.Item key={key}  onClick = { () => props.onNavClick(e) } >{e.name}</Dropdown.Item>)}
							</Dropdown.Menu>
						</Dropdown>

						<Dropdown > 
							<Dropdown.Toggle > Commodities </Dropdown.Toggle>
								<Dropdown.Menu  > {indexes?.commodities?.map((e, key) => 
									<Dropdown.Item key={key}   
									state = {props.state} onClick = { () => props.onNavClick(e) }>{e.name} </Dropdown.Item>)}
								</Dropdown.Menu> 
						</Dropdown>

						<Dropdown> <Dropdown.Toggle > Crypto </Dropdown.Toggle>
								<Dropdown.Menu > {indexes?.cryptos?.map((e, key) =>
									<Dropdown.Item key={key}  
									onClick = { () => props.onNavClick(e) }> {e.name} </Dropdown.Item>)}
							</Dropdown.Menu> 
						</Dropdown>

						<Button id = 'stocks' state = {props.state} onClick = { () => props.onNavClick({ component : "stocks",}) } >Stocks</Button>
						<Button id = 'about' onClick = { () => props.onNavClick({ component : "about",}) } >About</Button>

						<Form.Label style={{color : 'lightgray', fontSize : 18, marginTop : 9, marginLeft : 8}}>{registeredUser?.fullname}</Form.Label>
						<Button variant = 'link' onClick = {() => props.onLogout() } style={{color : 'lightgray', fontSize : 16, marginTop : '3px'}}>Logout</Button>
					</Nav>
				</Collapse> 
			</Navbar>
		</div>
	)
}

