import React, { useState,  } from 'react';
import packageJson from '../../package.json';

export default function Footer(props) {
	const [screenSize] = useState(window.screen);

	return (
		<footer style={{display:'none'}}>
			<p style={{margin : 0}} >v {packageJson.version}  Copyright &copy; Theseus 2024: 
			<span style = {{fontSize : 12, color : 'gray'}}> {screenSize.width + 'x' +screenSize.height} </span></p>
		</footer>
	)
}


